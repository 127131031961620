<template>
  <nav class="relative flex flex-col tab:w-12 pc:min-w-sidenav border-r border-gray-400">
    <span class="align-center flex justify-center mb-4 mt-3 pc:px-3">
      <div class="tab:hidden py-4 w-full text-center rounded">
        <strong class="text-gray-700">{{ companyName }}</strong>
      </div>
      <div class="flex- pc:hidden inline-flex items-center justify-center w-8 h-8 bg-indigo-200 rounded-full">
        <span class="text-indigo-900 font-bold">{{ omitCompanyName }}</span>
      </div>
    </span>
    <side-nav-link
      v-if="role === 'store_admin' || role === 'store_staff'"
      to="/companies"
      icon="company"
      title="出店会社管理"
    />
    <side-nav-link
      v-if="role === 'store_admin' || role === 'store_staff'"
      to="/brands"
      icon="tenant"
      title="出店ブランド管理"
    />
    <side-nav-link
      v-if="role === 'store_admin' || role === 'store_staff'"
      to="/product-tags"
      icon="hashtag"
      title="タグ管理"
    />
    <side-nav-link to="/products" icon="shopping_bag" title="商品管理" />
    <side-nav-link
      v-if="role === 'store_admin' || role === 'store_staff'"
      to="/orders"
      icon="shopping_cart"
      title="注文管理"
    />
    <side-nav-link
      v-if="role === 'store_admin' || role === 'store_staff'"
      to="/store/delivery"
      icon="shopping_bag_check"
      title="商品受渡管理"
    />
    <side-nav-link v-if="isDisplayBrandDirectShipping" to="/brand-direct-shippings" icon="truck" title="ブランド直送" />
    <side-nav-link
      v-if="role === 'store_admin' || role === 'store_staff'"
      to="/catalog"
      icon="tag"
      title="Webカタログ管理"
    />
    <side-nav-link
      v-if="role === 'store_admin' || role === 'store_staff'"
      to="/coupons"
      icon="coupon"
      title="クーポン管理"
    />
    <side-nav-link to="/reports" icon="chart" title="レポート" />
    <side-nav-link to="/manuals" icon="document" title="マニュアル" />
    <side-nav-link
      v-if="role === 'store_admin' || role === 'store_staff'"
      to="/short-urls"
      icon="link"
      title="短縮URL管理"
    />
    <side-nav-link
      v-if="role === 'store_admin' || role === 'tenant_admin'"
      to="/users"
      icon="users"
      title="ログインユーザー管理"
    />
    <bs-dropdown-menu class="mt-1 text-sm tab:border-t-2">
      <template #title>
        <div class="flex my-1 h-5">
          <bs-icon name="user_circle" class="relative tab:left-0.5 pc:mr-3 pc:w-4 tab:w-5 h-auto" />
          <span class="tab:hidden">マイメニュー</span>
        </div>
      </template>
      <template #body>
        <side-nav-link to="/mypage" icon="settings" title="アカウント設定" class="pc:my-1.5" />
        <button
          class="flex items-center pc:my-1.5 px-3 py-3 w-full text-left text-gray-700 text-sm"
          @click="handleClickLogoutButton"
        >
          <div class="flex h-5">
            <bs-icon name="logout" class="relative pc:left-0.5 tab:left-1 pc:mr-3 pc:w-4 tab:w-5 h-auto" />
            <span class="tab:hidden">ログアウト</span>
          </div>
        </button>
      </template>
    </bs-dropdown-menu>
  </nav>
</template>

<script lang="ts">
import { isBrandDirectShippingForAllBrand, isBrandDirectShippingForMyBrand } from "~/shared/role";
import { useMeStore } from "~/stores/me";

const meStore = useMeStore();
export default defineComponent({
  computed: {
    role(): string {
      return meStore.role ?? "";
    },
    companyName(): string {
      return meStore.me?.companyName ?? "";
    },
    omitCompanyName(): string {
      if (!this.companyName) {
        return "";
      }

      return this.companyName.charAt(0);
    },
    isDisplayBrandDirectShipping(): boolean {
      return isBrandDirectShippingForMyBrand(this.role) || isBrandDirectShippingForAllBrand(this.role);
    },
  },
  methods: {
    async handleClickLogoutButton() {
      await this.$auth.logout();
    },
  },
});
</script>
