import { defineStore } from "pinia";
import { User } from "~/types/user";

interface State {
  me: User | null;
  role: string;
}

export const useMeStore = defineStore("me", () => {
  const state: State = reactive({
    me: null,
    role: "",
  });
  const actions = {
    async getMe() {
      const { data } = await useBonsaiApi<User>("/admin/me");
      if (data.value !== null) {
        this.updateMe(data.value);
      }
    },
    updateMe(me: User) {
      state.me = me;
    },
    setRole({ role }: { role: string }) {
      state.role = role;
    },
  };

  return {
    ...toRefs(readonly(state)),
    ...actions,
  };
});
