import { Auth0Client } from "@auth0/auth0-spa-js";

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();
  const domain = runtimeConfig.public.auth0Domain as string;
  const clientId = runtimeConfig.public.auth0ClientId as string;
  const audience = runtimeConfig.public.auth0Audience as string;
  const auth0 = new Auth0Client({
    domain,
    clientId,
    authorizationParams: {
      redirect_uri: `${window.location.origin}/callback`,
      audience,
      scope: "openid profile email offline_access",
    },
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
  });

  const login = async () => {
    await auth0.loginWithRedirect();
  };

  const getToken = () => {
    return auth0.getTokenSilently();
  };

  const logout = () => {
    auth0.logout();
  };

  const getUser = () => {
    return auth0.getUser();
  };

  const handleRedirectCallback = () => {
    return auth0.handleRedirectCallback();
  };

  const isAuthenticated = () => {
    return auth0.isAuthenticated();
  };

  const checkSession = () => {
    return auth0.checkSession();
  };

  const auth = {
    login,
    getToken,
    logout,
    getUser,
    handleRedirectCallback,
    isAuthenticated,
    checkSession,
  };

  return {
    provide: {
      auth,
    },
  };
});
