import { defu } from "defu";
import type { UseFetchOptions } from "nuxt/app";
import type { FetchOptions, FetchResponse } from "ofetch";
import { ErrorParams } from "@/shared/error";

const createErrorParams = (option?: Partial<ErrorParams>): ErrorParams => ({
  statusCode: option?.statusCode ?? 500,
  message: option?.message ?? "unknown error",
});

export async function useBonsaiApi<T>(url: string, options: UseFetchOptions<T> = {}) {
  const { $auth } = useNuxtApp();
  const config = useRuntimeConfig();

  const defaults: UseFetchOptions<T> = {
    baseURL: (config.public.baseUrl as string) ?? "http://localhost:3000",
    // cache request
    key: url,

    async onRequest({ options }: { options: FetchOptions }) {
      options.headers = options.headers || { "Content-Type": "application/json" };
      const token = await $auth.getToken();
      // set user token if connected
      if (token) {
        options.headers.Authorization = `Bearer ${token}`;
      }
    },

    // https://github.com/unjs/ofetch/blob/v1.1.1/README.md#onresponseerror-request-options-response-
    // https://github.com/unjs/ofetch/blob/v1.1.1/src/error.ts
    onResponseError({ options, response }: { options: FetchOptions; response: FetchResponse }) {
      const statusCode = response?.status ?? 500;

      // dontDisplayErrorPage 付与時、400 時はエラーページ表示はスキップ
      if (!options.dontDisplayErrorPage && statusCode !== 400) {
        showError({
          statusCode,
          fatal: true,
        });
      }

      // https://github.com/unjs/ofetch/blob/v1.1.1/src/fetch.ts#L22-L24
      return Promise.reject(
        createErrorParams({
          message: response._data?.message ?? response.statusText,
          statusCode: response._data?.statusCode,
        }),
      );
    },
  };

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults);

  // https://github.com/nuxt/nuxt/blob/v3.5.3/packages/nuxt/src/app/composables/fetch.ts#L109-L123
  // https://github.com/nuxt/nuxt/blob/v3.5.3/packages/nuxt/src/app/composables/asyncData.ts#L170-L176
  const asyncData = await useFetch(url, params);
  if (asyncData.error.value) {
    throw asyncData.error.value;
  }

  return asyncData;
}
