// @see https://heroicons.com/
// 使えるものがあれば上記 URL から SVG を選んで使用する
// 先の太さは stroke-width="2" に統一する

export const ICONS = {
  company: "icon_building",
  coupon: "icon_coupon",
  shopping_bag: "icon_bag",
  shopping_bag_check: "bag_check",
  document: "icon_document",
  download: "icon_download",
  users: "icon_users",
  chart: "icon_chart",
  shopping_cart: "icon_shopping_cart",
  hashtag: "icon_hashtag",
  tag: "icon_tag",
  tenant: "icon_tenant",
  search: "icon_search",
  success: "icon_check_circle",
  warn: "icon_exclamation_circle",
  error: "icon_x_circle",
  info: "icon_info_circle",
  chevron_down: "icon_chevron_down",
  user_circle: "icon_user_circle",
  logout: "icon_logout",
  settings: "icon_settings",
  close: "icon_close",
  movie: "icon_movie",
  link: "icon_link",
  truck: "icon_truck",
} as const;

export type IconType = keyof typeof ICONS;
