import * as Sentry from "@sentry/vue";

const ignoredMessageList: string[] = ["Unable to preload CSS"];

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();

  Sentry.init({
    enabled: runtimeConfig.public.SENTRY_ENABLED,
    // For debug
    // enabled: true,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: runtimeConfig.public.ENV !== "production",
    dsn: runtimeConfig.public.SENTRY_DSN,
    release: runtimeConfig.public.SENTRY_RELEASE,
    environment: runtimeConfig.public.SENTRY_ENVIRONMENT,
    trackComponents: false,
    hooks: ["activate", "create", "destroy", "mount", "update"],
    tracesSampleRate: 0,
    ignoreErrors: ignoredMessageList,
  });

  return {
    provide: {
      sentry: Sentry,
      sentrySetUser: Sentry.setUser,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
