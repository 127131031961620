<template>
  <nuxt-link
    :to="to"
    class="align-center flex items-center tab:justify-center my-1 pc:px-3 tab:py-2 pc:py-3 text-gray-700 text-sm"
  >
    <span class="pc:mr-3 pc:w-4 tab:w-5">
      <bs-icon :name="icon" class="relative tab:mr-0 w-full h-auto" />
    </span>
    <span class="tab:hidden">{{ title }}</span>
  </nuxt-link>
</template>

<script lang="ts">
import { IconType } from "~/constants/icons";
import type { PropType } from "vue";

export default defineComponent({
  props: {
    to: {
      type: String,
      default: "/",
    },
    icon: {
      type: String as PropType<IconType>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped>
.nuxt-link-active {
  background: #4c51bf;
  color: #fff;
  font-weight: bold;
}
</style>
