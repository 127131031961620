import { default as editjrAlOmvW6QMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/[id]/edit.vue?macro=true";
import { default as indexjsfNUniaroMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/index.vue?macro=true";
import { default as shipment_45statusAYLPYv7a3zMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/shipment-status.ts?macro=true";
import { default as shipping_45companiesEq9xhBMCNSMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/shipping-companies.ts?macro=true";
import { default as editoaZQxxeLl8Meta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/[id]/edit.vue?macro=true";
import { default as indexRG2r1JtevGMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/[id]/index.vue?macro=true";
import { default as createBtm8B1TB1vMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/create.vue?macro=true";
import { default as indexcL2L7KwAYUMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/index.vue?macro=true";
import { default as callbackAsrpm3OEEVMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/callback.vue?macro=true";
import { default as indexzv22yHWrAqMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/edit/index.vue?macro=true";
import { default as indexYYYlUuEVEwMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/index.vue?macro=true";
import { default as indexzMMIStiAmlMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/products/index.vue?macro=true";
import { default as order4ABIiCs4WcMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/products/order.vue?macro=true";
import { default as createwgfAtXVovlMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/create.vue?macro=true";
import { default as indexGnu7E00L3kMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/index.vue?macro=true";
import { default as indexurYGwhENIlMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/index.vue?macro=true";
import { default as editpURRnB16Y2Meta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/[id]/edit.vue?macro=true";
import { default as indexyEgWWdmodHMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/[id]/index.vue?macro=true";
import { default as createtlF9KPZELpMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/create.vue?macro=true";
import { default as indexT8oeXNwjasMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/index.vue?macro=true";
import { default as editAFvNcLHk4eMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/coupons/[id]/edit.vue?macro=true";
import { default as createLY3bhGiBdhMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/coupons/create.vue?macro=true";
import { default as indexqV9Dj18HQFMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/coupons/index.vue?macro=true";
import { default as _4043sT9QHXBNnMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/errors/404.vue?macro=true";
import { default as invalidUrlG3qyQePzY7Meta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/errors/invalidUrl.vue?macro=true";
import { default as indexaNmba3wSvEMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/index.vue?macro=true";
import { default as login0DxjNJDnxxMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/login.vue?macro=true";
import { default as index1kcVSuSETsMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/manuals/index.vue?macro=true";
import { default as indexBWRGFpgxnUMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/mypage/index.vue?macro=true";
import { default as indexw1tNxr8ldPMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/direct/[slug]/index.vue?macro=true";
import { default as indexPnRjaBHkUVMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/ec/[slug]/index.vue?macro=true";
import { default as indexbH0hik6vOfMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/ec/[slug]/refund/index.vue?macro=true";
import { default as indexEylHlpBhztMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/index.vue?macro=true";
import { default as index1wjuJZIjRoMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/store/[slug]/index.vue?macro=true";
import { default as editHMLnftNb4iMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/product-tags/[id]/edit.vue?macro=true";
import { default as create1TFss9cjPxMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/product-tags/create.vue?macro=true";
import { default as index84K6B5VksVMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/product-tags/index.vue?macro=true";
import { default as editqtTS3nss62Meta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/edit.vue?macro=true";
import { default as indexzRy8NzqhFdMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/index.vue?macro=true";
import { default as editDzdfDfdpXdMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/variants/[variant_id]/edit.vue?macro=true";
import { default as createKaQfprdEbVMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/variants/create.vue?macro=true";
import { default as create7hkuccgZLgMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/create.vue?macro=true";
import { default as indexYasC8EuCbEMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/index.vue?macro=true";
import { default as indexgqHavcsFaeMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/dates/index.vue?macro=true";
import { default as indexk7HigkNblQMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/index.vue?macro=true";
import { default as _91slug_93QyVtQzoxLYMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/orders/[id]/skus/[slug].vue?macro=true";
import { default as indexNjrhiHSWqQMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/skus/index.vue?macro=true";
import { default as editJw1FoUng5QMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/short-urls/[id]/edit.vue?macro=true";
import { default as createpgKuuXMvT7Meta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/short-urls/create.vue?macro=true";
import { default as indexYymZWpLim7Meta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/short-urls/index.vue?macro=true";
import { default as indexYu2coYk6xHMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/store/delivery/index.vue?macro=true";
import { default as indexxrUxPz8JfQMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/users/[id]/index.vue?macro=true";
import { default as create4eOvSpd9jNMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/users/create.vue?macro=true";
import { default as indexN0KJ3pt8NgMeta } from "/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/users/index.vue?macro=true";
export default [
  {
    name: "brand-direct-shippings-id-edit___en",
    path: "/en/brand-direct-shippings/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "brand-direct-shippings-id-edit___ja",
    path: "/brand-direct-shippings/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "brand-direct-shippings___en",
    path: "/en/brand-direct-shippings",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/index.vue").then(m => m.default || m)
  },
  {
    name: "brand-direct-shippings___ja",
    path: "/brand-direct-shippings",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/index.vue").then(m => m.default || m)
  },
  {
    name: "brand-direct-shippings-shipment-status___en",
    path: "/en/brand-direct-shippings/shipment-status",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/shipment-status.ts").then(m => m.default || m)
  },
  {
    name: "brand-direct-shippings-shipment-status___ja",
    path: "/brand-direct-shippings/shipment-status",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/shipment-status.ts").then(m => m.default || m)
  },
  {
    name: "brand-direct-shippings-shipping-companies___en",
    path: "/en/brand-direct-shippings/shipping-companies",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/shipping-companies.ts").then(m => m.default || m)
  },
  {
    name: "brand-direct-shippings-shipping-companies___ja",
    path: "/brand-direct-shippings/shipping-companies",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brand-direct-shippings/shipping-companies.ts").then(m => m.default || m)
  },
  {
    name: "brands-id-edit___en",
    path: "/en/brands/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "brands-id-edit___ja",
    path: "/brands/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___en",
    path: "/en/brands/:id()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-id___ja",
    path: "/brands/:id()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-create___en",
    path: "/en/brands/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/create.vue").then(m => m.default || m)
  },
  {
    name: "brands-create___ja",
    path: "/brands/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/create.vue").then(m => m.default || m)
  },
  {
    name: "brands___en",
    path: "/en/brands",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "brands___ja",
    path: "/brands",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "callback___en",
    path: "/en/callback",
    meta: callbackAsrpm3OEEVMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: "callback___ja",
    path: "/callback",
    meta: callbackAsrpm3OEEVMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas-id-edit___en",
    path: "/en/catalog/areas/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas-id-edit___ja",
    path: "/catalog/areas/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas-id___en",
    path: "/en/catalog/areas/:id()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas-id___ja",
    path: "/catalog/areas/:id()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas-id-products___en",
    path: "/en/catalog/areas/:id()/products",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/products/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas-id-products___ja",
    path: "/catalog/areas/:id()/products",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/products/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas-id-products-order___en",
    path: "/en/catalog/areas/:id()/products/order",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/products/order.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas-id-products-order___ja",
    path: "/catalog/areas/:id()/products/order",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/[id]/products/order.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas-create___en",
    path: "/en/catalog/areas/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/create.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas-create___ja",
    path: "/catalog/areas/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/create.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas___en",
    path: "/en/catalog/areas",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-areas___ja",
    path: "/catalog/areas",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/areas/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog___en",
    path: "/en/catalog",
    meta: indexurYGwhENIlMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog___ja",
    path: "/catalog",
    meta: indexurYGwhENIlMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "companies-id-edit___en",
    path: "/en/companies/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "companies-id-edit___ja",
    path: "/companies/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "companies-id___en",
    path: "/en/companies/:id()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "companies-id___ja",
    path: "/companies/:id()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "companies-create___en",
    path: "/en/companies/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/create.vue").then(m => m.default || m)
  },
  {
    name: "companies-create___ja",
    path: "/companies/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/create.vue").then(m => m.default || m)
  },
  {
    name: "companies___en",
    path: "/en/companies",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "companies___ja",
    path: "/companies",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "coupons-id-edit___en",
    path: "/en/coupons/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/coupons/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "coupons-id-edit___ja",
    path: "/coupons/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/coupons/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "coupons-create___en",
    path: "/en/coupons/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/coupons/create.vue").then(m => m.default || m)
  },
  {
    name: "coupons-create___ja",
    path: "/coupons/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/coupons/create.vue").then(m => m.default || m)
  },
  {
    name: "coupons___en",
    path: "/en/coupons",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: "coupons___ja",
    path: "/coupons",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: "errors-404___en",
    path: "/en/errors/404",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/errors/404.vue").then(m => m.default || m)
  },
  {
    name: "errors-404___ja",
    path: "/errors/404",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/errors/404.vue").then(m => m.default || m)
  },
  {
    name: "errors-invalidUrl___en",
    path: "/en/errors/invalidUrl",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/errors/invalidUrl.vue").then(m => m.default || m)
  },
  {
    name: "errors-invalidUrl___ja",
    path: "/errors/invalidUrl",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/errors/invalidUrl.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexaNmba3wSvEMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ja",
    path: "/",
    meta: indexaNmba3wSvEMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: login0DxjNJDnxxMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___ja",
    path: "/login",
    meta: login0DxjNJDnxxMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "manuals___en",
    path: "/en/manuals",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/manuals/index.vue").then(m => m.default || m)
  },
  {
    name: "manuals___ja",
    path: "/manuals",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/manuals/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage___en",
    path: "/en/mypage",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage___ja",
    path: "/mypage",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-direct-slug___en",
    path: "/en/orders/direct/:slug()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/direct/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-direct-slug___ja",
    path: "/orders/direct/:slug()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/direct/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-ec-slug___en",
    path: "/en/orders/ec/:slug()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/ec/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-ec-slug___ja",
    path: "/orders/ec/:slug()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/ec/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-ec-slug-refund___en",
    path: "/en/orders/ec/:slug()/refund",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/ec/[slug]/refund/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-ec-slug-refund___ja",
    path: "/orders/ec/:slug()/refund",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/ec/[slug]/refund/index.vue").then(m => m.default || m)
  },
  {
    name: "orders___en",
    path: "/en/orders",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "orders___ja",
    path: "/orders",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-store-slug___en",
    path: "/en/orders/store/:slug()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/store/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-store-slug___ja",
    path: "/orders/store/:slug()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/orders/store/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "product-tags-id-edit___en",
    path: "/en/product-tags/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/product-tags/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "product-tags-id-edit___ja",
    path: "/product-tags/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/product-tags/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "product-tags-create___en",
    path: "/en/product-tags/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/product-tags/create.vue").then(m => m.default || m)
  },
  {
    name: "product-tags-create___ja",
    path: "/product-tags/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/product-tags/create.vue").then(m => m.default || m)
  },
  {
    name: "product-tags___en",
    path: "/en/product-tags",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/product-tags/index.vue").then(m => m.default || m)
  },
  {
    name: "product-tags___ja",
    path: "/product-tags",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/product-tags/index.vue").then(m => m.default || m)
  },
  {
    name: "products-id-edit___en",
    path: "/en/products/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "products-id-edit___ja",
    path: "/products/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "products-id___en",
    path: "/en/products/:id()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-id___ja",
    path: "/products/:id()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-id-variants-variant_id-edit___en",
    path: "/en/products/:id()/variants/:variant_id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/variants/[variant_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "products-id-variants-variant_id-edit___ja",
    path: "/products/:id()/variants/:variant_id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/variants/[variant_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "products-id-variants-create___en",
    path: "/en/products/:id()/variants/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/variants/create.vue").then(m => m.default || m)
  },
  {
    name: "products-id-variants-create___ja",
    path: "/products/:id()/variants/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/[id]/variants/create.vue").then(m => m.default || m)
  },
  {
    name: "products-create___en",
    path: "/en/products/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/create.vue").then(m => m.default || m)
  },
  {
    name: "products-create___ja",
    path: "/products/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/create.vue").then(m => m.default || m)
  },
  {
    name: "products___en",
    path: "/en/products",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "products___ja",
    path: "/products",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-dates___en",
    path: "/en/reports/dates",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/dates/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-dates___ja",
    path: "/reports/dates",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/dates/index.vue").then(m => m.default || m)
  },
  {
    name: "reports___en",
    path: "/en/reports",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "reports___ja",
    path: "/reports",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-orders-id-skus-slug___en",
    path: "/en/reports/orders/:id()/skus/:slug()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/orders/[id]/skus/[slug].vue").then(m => m.default || m)
  },
  {
    name: "reports-orders-id-skus-slug___ja",
    path: "/reports/orders/:id()/skus/:slug()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/orders/[id]/skus/[slug].vue").then(m => m.default || m)
  },
  {
    name: "reports-skus___en",
    path: "/en/reports/skus",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/skus/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-skus___ja",
    path: "/reports/skus",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/reports/skus/index.vue").then(m => m.default || m)
  },
  {
    name: "short-urls-id-edit___en",
    path: "/en/short-urls/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/short-urls/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "short-urls-id-edit___ja",
    path: "/short-urls/:id()/edit",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/short-urls/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "short-urls-create___en",
    path: "/en/short-urls/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/short-urls/create.vue").then(m => m.default || m)
  },
  {
    name: "short-urls-create___ja",
    path: "/short-urls/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/short-urls/create.vue").then(m => m.default || m)
  },
  {
    name: "short-urls___en",
    path: "/en/short-urls",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/short-urls/index.vue").then(m => m.default || m)
  },
  {
    name: "short-urls___ja",
    path: "/short-urls",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/short-urls/index.vue").then(m => m.default || m)
  },
  {
    name: "store-delivery___en",
    path: "/en/store/delivery",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/store/delivery/index.vue").then(m => m.default || m)
  },
  {
    name: "store-delivery___ja",
    path: "/store/delivery",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/store/delivery/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id___en",
    path: "/en/users/:id()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id___ja",
    path: "/users/:id()",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-create___en",
    path: "/en/users/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: "users-create___ja",
    path: "/users/create",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: "users___en",
    path: "/en/users",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "users___ja",
    path: "/users",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "custom404___en",
    path: "/en/:path*",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/errors/404.vue").then(m => m.default || m)
  },
  {
    name: "custom404___ja",
    path: "/:path*",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/errors/404.vue").then(m => m.default || m)
  },
  {
    name: "inValidUrlError___en",
    path: "/en/:pathMatch(.*)*",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/errors/invalidUrl.vue").then(m => m.default || m)
  },
  {
    name: "inValidUrlError___ja",
    path: "/:pathMatch(.*)*",
    component: () => import("/home/runner/work/ss-bonsai-admin-frontend/ss-bonsai-admin-frontend/pages/errors/invalidUrl.vue").then(m => m.default || m)
  }
]