<template>
  <component :is="svg" view-box="0 0 96 96" width="96" height="96" class="bs-icon" />
</template>

<script lang="ts">
import { ICONS, IconType } from "~/constants/icons";
import type { PropType } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String as PropType<IconType>,
      required: true,
    },
  },

  computed: {
    svg() {
      const filename = ICONS[this.name];
      // https://v3-migration.vuejs.org/ja/breaking-changes/async-components.html
      return defineAsyncComponent(() => import(`~/assets/icons/${filename}.svg`));
    },
  },
});
</script>

<style scoped>
.bs-icon {
  height: auto;
}
</style>
