<template>
  <div class="relative inline-block my-1 text-left">
    <div>
      <button
        id="options-menu"
        type="button"
        class="relative inline-flex items-center pc:p-3 tab:px-3 tab:py-2 w-full"
        aria-haspopup="true"
        aria-expanded="true"
        @click="handcleClickMenuButton"
      >
        <slot name="title" />
        <bs-icon name="chevron_down" class="absolute bottom-0 right-3 top-0 tab:hidden m-auto" width="12" />
      </button>
    </div>

    <div
      :class="{ hidden: !isOpen }"
      class="flex flex-col w-full"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <slot name="body" />
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    handcleClickMenuButton() {
      this.isOpen = !this.isOpen;
    },
  },
});
</script>
