<template>
  <div class="flex min-h-screen">
    <side-nav />
    <div class="contents pb-20">
      <slot />
      <div class="modal-wrapper"></div>
      <bs-notify />
    </div>
  </div>
</template>

<script lang="ts">
import { useMeStore } from "~/stores/me";
import { setSentryUserData } from "~/utils/sentry";

export default defineComponent({
  async mounted() {
    const user = await this.$auth.getUser();
    const role = user?.["https://api.choosebase.jp/roles"][0] ?? "";
    const meStore = useMeStore();
    meStore.setRole({ role });
    setSentryUserData(this.$sentry, {
      sub: user?.sub || "unknown user",
    });
  },
});
</script>

<style>
@screen pc {
  :root {
    --sidenav-width: 208px;
  }
}
@screen tab {
  :root {
    --sidenav-width: 48px;
  }
}
</style>

<style scoped>
.contents {
  display: block;
  width: calc(100vw - var(--sidenav-width));
}

.modal-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
