<template>
  <notifications :duration="5000" :speed="100">
    <template #body="{ item, close }">
      <div class="notify">
        <div class="notify__container">
          <div class="notify__icon">
            <bs-icon :name="item.type" class="notify__icon__item" :class="item.type" width="21" height="21" />
          </div>
          <div class="notify__body">
            <p class="notify__body__title">
              {{ item.title }}
            </p>
            <p class="notify__body__text">
              {{ item.text }}
            </p>
          </div>
          <button class="notify__close close" @click="close">×</button>
        </div>
      </div>
    </template>
  </notifications>
</template>

<style scoped>
.notify {
  width: 280px;
  background: white;
  border: solid 1px #cbd5e0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(142 142 142 / 50%);
}

/* stylelint-disable selector-class-pattern */
.notify__container {
  padding: 12px 8px;
  @apply relative flex;
}

.notify__body {
  padding-right: 40px;
}

.notify__close {
  font-family: ui-monospace, sans-serif;
  font-size: 28px;
  top: -2px;
  right: 16px;
  @apply absolute text-gray-400;
}

.notify__close:focus {
  @apply outline-none;
}

.notify__body__title {
  font-size: 14px;
  @apply font-bold;
}

.notify__body__text {
  font-size: 13px;
  margin-top: 4px;
  @apply text-gray-800 break-all;
}

.notify__icon {
  margin: 0 8px;
  margin-top: 1px;
}

.notify__icon__item.success {
  @apply text-green-500;
}

.notify__icon__item.warn {
  @apply text-yellow-500;
}

.notify__icon__item.error {
  @apply text-red-500;
}

.notify__icon__item.info {
  @apply text-gray-500;
}
/* stylelint-enable selector-class-pattern */
</style>

<style>
/* デフォルトの UI を上書き */
.vue-notification-group {
  top: 16px !important;
}

.vue-notification-wrapper {
  overflow: initial !important;
  margin: 8px 0 !important;
}
</style>
