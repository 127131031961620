export type ErrorParams = {
  statusCode: number;
  message: string | Array<string>;
};

export type ErrorResponse = {
  status: number | undefined;
  errorMessage: string | undefined;
};

export const createErrorMessage = (statusCode: number) => {
  switch (statusCode) {
    case 401:
      return {
        statusCode,
        title: "続けるにはログインが必要です。ログインしてから再度お試しください",
        definition: "Unauthorized",
      };
    case 403:
      return {
        statusCode,
        title: "アクセスが許可されていないため、ページを表示できませんでした",
        definition: "Forbidden",
      };
    case 404:
      return {
        statusCode,
        title: "お探しのページは見つかりませんでした",
        definition: "Not Found",
      };
    case 500:
      return {
        statusCode,
        title: "サーバーで問題が発生しているため、ページが表示できませんでした",
        definition: "Internal Server Error",
      };
    case 503:
      return {
        statusCode,
        title: "一時的にサーバーが停止しているため、ページが表示できませんでした",
        definition: "Service Unavailable",
      };
    default:
      return {
        statusCode,
        title: "問題が発生したため、ページが表示できませんでした",
        definition: "Bad Request",
      };
  }
};
