import { useMeStore } from "~/stores/me";

export default defineNuxtRouteMiddleware(async (to) => {
  const meStore = useMeStore();
  if (to.path === "/login") {
    return;
  }
  if (!meStore.me) {
    try {
      await meStore.getMe();
    } catch (err) {
      return navigateTo("/login");
    }
  }
});
