export default defineNuxtRouteMiddleware(async (to) => {
  const { $auth } = useNuxtApp();
  if (to.path === "/callback" || to.path === "/login") {
    return;
  }

  await $auth.checkSession();
  const isAuthenticated = await $auth.isAuthenticated();

  if (!isAuthenticated) {
    return navigateTo("/login");
  }
});
