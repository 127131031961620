enum Role {
  StoreAdmin = "store_admin", // ストア管理者
  StoreStaff = "store_staff", // ストアスタッフ
  TenantAdmin = "tenant_admin", // ブランド管理者
  TenantStaff = "tenant_staff", // ブランド作業者
}
export const isReadableAddressOfLiquor = (role: string): boolean => {
  return [Role.TenantAdmin.toString(), Role.TenantStaff.toString(), Role.StoreStaff.toString()].includes(role);
};

export const isBrandDirectShippingForMyBrand = (role: string): boolean => {
  return [Role.TenantAdmin.toString(), Role.TenantStaff.toString()].includes(role);
};

export const isBrandDirectShippingForAllBrand = (role: string): boolean => {
  // ストア管理者は配送先等の個人情報を見る必要は無いため、除外
  return [Role.StoreStaff.toString()].includes(role);
};
