<template>
  <div class="flex flex-col items-center justify-center h-full">
    <div class="flex items-center">
      <h1 class="mr-4 pr-4 border-r-2 border-gray-300">
        <span class="text-2xl">{{ errorMessage.statusCode }}</span>
      </h1>
      <p class="text-gray-700 text-sm font-bold">
        {{ errorMessage.title }}
      </p>
    </div>
    <span class="text-gray-500">
      {{ errorMessage.definition }}
    </span>
  </div>
</template>

<script lang="ts">
import { createErrorMessage } from "~/shared/error";
import type { PropType } from "vue";

interface Error {
  statusCode: number;
  message: string;
}

export default defineComponent({
  props: {
    error: {
      type: Object as PropType<Error>,
      required: true,
    },
  },

  computed: {
    errorMessage() {
      return createErrorMessage(this.error.statusCode);
    },
  },
});
</script>
