import * as SentryTypes from "@sentry/hub";

export function setSentryUserData(sentry: typeof SentryTypes, user: { sub: string }) {
  /**
   * sub には `auth0|xxxxxxxxxxxxxxx のような provider と user_id が組み合わさった文字列が入ってくる
   */
  const id = user.sub.split("|").pop()?.trim();
  sentry.configureScope((scope) => {
    scope.setUser({ id });
  });
}
