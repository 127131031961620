<template>
  <div class="min-h-screen">
    <slot />
    <div class="modal-wrapper"></div>
    <bs-notify />
  </div>
</template>
<style scoped>
.modal-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
